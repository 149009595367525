import styled from '@emotion/styled';
import facepaint from 'facepaint';


// Colors
export const white = "#FFFFFF";
export const tintedblack="#1f1f1f"
export const black ="#3f3f3f"
export const darkgrey = "#949494"
export const darkergrey = "#bfbfbf"
export const tintedgrey ="#e6e6e6"
export const grey ="#f5f5f5"
export const lightgrey ="#F7F7F7";
export const darkblue = "#171C8F"
export const dark = "#000";
export const darker ="#29003F"
export const tintedorange ="#c44b04"
export const orange ="#DA5304";
export const lighter ="#79003D"
export const light ="#F89801"
export const blue = "#474CDE"


export const background1 = "#FCFAF8"
export const background2 = "#F9F4F0"
export const background3 = "#F4EAE1"

const breakpoints = [576, 768, 992, 1200]

export const mq = facepaint(
    breakpoints.map(bp => `@media (min-width: ${bp}px)`)
  )

export const Wrapper = styled.div(mq({
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: ["13px", "13px", "14px", "15px", "15px"],
    overflowX: "hidden"
}),
props => ({backgroundColor: props.background})
)
export const Container = styled.div(mq({
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "1240px",
    width: "100%",
    height: "auto",
}))

export const FlexContainer = styled.div({
    width: "100%",
    paddingTop: '6em',
    paddingBottom: '6em',
    paddingRight: '6%',
    height: 'auto',
    display: "flex",
    overflow: "visible",
    position: "relative",
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1920px',
    },
    mq({
        paddingLeft: ['5%', '5%', '11%', '11%']
    }),
        props => ({justifyContent: props.justify}),
        props => ({backgroundColor: props.background}),
        props => ({alignItems: props.align}),
        props => ({flexDirection: props.direction})
   )
export const FlexContainerRow = styled.div({
    width: "100%",
    paddingTop: '6em',
    paddingBottom: '6em',
    paddingRight: '6%',
    height: 'auto',
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "visible",
    position: "relative",
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1920px',
    },
    mq({
        paddingLeft: ['5%', '5%', '11%', '11%'],
        flexDirection: ['column', 'column', 'row', 'row']
    }),
   )
export const FlexContainerColumn = styled.div({
    width: "100%",
    paddingTop: '6em',
    paddingBottom: '6em',
    height: 'auto',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "visible",
    position: "relative",
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1920px',
    },
    mq({
        paddingRight: ["5%", "5%", "6%", "6%"],
        paddingLeft: ['5%', '5%', '11%', '11%'],
        alignItems: ["flex-start", "flex-start", "flex-start", "flex-start"]
    }),
   )

  export const FlexBox = styled.div({
    height: "auto",
    padding: 0,
    display: "flex",
    overflow: "visible",
    position: "relative",
    minWidth: 0,
    },
    mq({
        width: ['100%', '100%', '45%', '45%']
    }),
    props => ({justifyContent: props.justify}),
    props => ({alignItems: props.align}),
    props => ({flexDirection: props.direction})
    )
  export const FlexBoxRow = styled.div({
    height: "auto",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "visible",
    position: "relative",
    },
    mq({
        flexDirection: ["column", "column", "row", "row"],
        width: ['100%', '100%', '100%', '100%']
    }),
    )
  export const FlexBoxRow2 = styled.div({
    height: "auto",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "visible",
    position: "relative",
    },
    mq({
        flexDirection: ["row", "row", "row", "row"],
        width: ['100%', '100%', '100%', '100%']
    }),
    )
  export const FlexBoxColumn = styled.div({
    height: "auto",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "visible",
    position: "relative",
    },
    mq({
        width: ['100%', '100%', '45%', '45%'],
        alignItems: ["center", "center", "flex-start", "flex-start"]
    }),
   )
export const TextBoxHalf = styled.div({
    flex: "100%",
    maxWidth: "100%",
    position: "relative",
    marginBottom: "2.4em"
})
export const TextBoxFull = styled.div({
    width: "100%",
    padding: "1em",
    ["p"]: {marginBottom: "1em", paddingLeft: "1em", paddingRight: "1em"},
})
export const siteheader = mq({
   paddingTop: "10em",
   paddingBottom: "8em",
   ["p"]: {fontSize: "1.2em", marginBottom: "1.2em"}
})
export const siteheaderevent = mq({
    paddingTop: ["6em", "6em", "5.4em", "5.4em" ],

})
export const eventformcontainertop = mq({
    marginTop: ["2em", "2em", 0,0],
    padding: "2em", 
    borderRadius: "0.4em"
})


export const eventformcontainerbottom = mq({
    background: grey,
    margin: [0,0,"2em 4em 2em 0","2em 4em 2em 0"],
    padding: "2em", 
    borderRadius: "2em 0",
    boxShadow: "0.2em 0.2em 0.8em -0.2em " + dark
})

// Akademie
export const akademiecard = mq({
    width: "16em !important", height: "100%", 
    flexDirection: "column", 
    justifyContent: "flex-start", 
    display: ["none", "none", "flex", "flex"]
})
export const akademiecardreferents = mq({
    width: ["auto", "auto", "16em !important"],
    transform: ["scale(0.9)", "scale(0.9)", "scale(1)"], 
    height: "100%", 
    flexDirection: "column", 
    justifyContent: "flex-start", 
    display: ["flex", "flex", "flex", "flex"],
    flexWrap: 'wrap'
})
export const akademiecardtitle = mq({
    width: ["100%", "100%","50%","50%"], 
    height: "100%", 
    flexDirection: "column", 
    justifyContent: "flex-start", 
})
export const akademiecarddate = mq({
    width: ["100%", "100%", "20%", "20%"], 
    height: "100%",
    overflow:" hidden",
    display: "flex", 
    flexDirection: ["column", "column", "column", "column"],
    justifyContent: "space-between", 
})

// Blog
export const blogcard =mq({
    flex: ["100%", "100%", "30%", "30%" ],
    fontSize: ["14px, 14px, 14px, 16px"],
    overflow: "visible",
    marginBottom: "2.4rem",

})

// Navigation

export const navmenu = mq({
    position: "fixed", 
    height: ["100vh", "100vh", "100vh", "100vh"],
    width: ["85%","70%", "55%","40%"],
    left: ["0%", "0%", "5%", "5%"],
    background: background2,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    overflowX: "hidden",
    overflowY: ["auto","auto", "auto", "hidden"],
    boxShadow: "-3px 0px 12px 2px " + darkgrey, 
    fontSize: "16px",
    paddingTop: ["8%", "8%", 0, 0]
})
export const navcloser = mq({
    height: ["100vh", "100vh", "100vh", "100vh"],
    width: ["20%","37%", "51%","68%"],
    left: ["80%","63%", "49%","32%"],
    position: "absolute", zIndex: 15
})
export const menusidebar = mq({
    width: ["100vw", "100vw", "5%","5%"],
    height: ["auto", "auto", "100%","100%"], 
    position: "fixed",
    marginLeft: "0%", 
    marginTop: "0%",
    zIndex: 12,
    background: white,
    boxShadow: "-5px 0px 8px 2px " +darkgrey,
    display: "flex",
    flexDirection: ["row", "row","column","column"],
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: ["0.4em", "0.4em","0.8em", "0.8em"],
    paddingBottom: ["0.6em", "0.6em","1.4em", "1.4em"],
    paddingLeft: ["1.4em", "1.4em", "auto", "auto"],
    paddingRight:  ["1.4em", "1.4em", "auto", "auto"]
})
export const menuicons = mq({
    height: ["auto", "auto", "15%","15%"], 
    display: ["none", "none", "flex","flex"],
    flexDirection: ["row", "row","column","column"],
    alignItems: "center",
    justifyContent: "space-between",
    order: 3
})
export const menulogo = mq({
    display: ["block","block","none","none"],
    height: "15%",
    width: "6em",
    order: 1
})
export const menucontact = mq({
    color: dark,
    height: ["auto", "auto", "20%","20%"],
    display: ["none","none","flex","flex"],
    flexDirection: ["row", "row","column","column"],
    alignItems: "center",
    justifyContent: "flex-start",
    height: "15%",
    order: 1,
})
export const Toggler = styled.div(
    {
    cursor: "pointer",
    height: "auto",
    width: "2.2rem",
    transition: "all 0.7s ease-out",
    position: "relative",
    justifyContent: "space-evenly", 
    flexDirection: "column",
    backgroundColor: "transparent",
    zIndex: 13,
    cursor: "pointer",
    order: 2
    },
    mq({
        display: ["flex", "flex", "flex", "flex"],
    })
)
export const headericon = mq({
    height: "auto",
    background: white,
    width: "10em",
    padding: "0.6rem",
    borderRadius: "6px",
    left: "11%",
    top: "2.5%",
    position: "fixed", 
    zIndex: 8,
    display: ["none", "none","block", "block" ]
})
export const headerBreadcrumps = mq({
    height: "auto",
    width: "auto",
    padding: "0.6rem",
    borderRadius: "6px",
    left: "50%",
    top: "2.5%",
    transform: "translateX(-50%)",
    position: "fixed", 
    zIndex: 8,
    display: ["none", "none","block", "block" ],
    fontFamily: "Montserrat",
    fontSize: "1.2rem"
})

export const headerbutton = mq({
    height: "auto",
    width: "auto",
    right: "2em",
    top:"1em",
    position: "fixed", 
    zIndex: 8,
    display: ["none", "none","block", "block" ]
})
export const eventbutton = mq({
    height: "100%",
    width: "100%",
    background: orange,
    borderRadius: "6px ",
    padding: "0.3em 1em",
    cursor: "pointer",
    fontSize: "14px",
})
export const navlink = mq({
    fontSize: ["0.7rem", "0.8rem", "0.9rem"],
    color: "inherit",
    marginBottom: "0.4em",
    transition: "all 0.2s ease-out",
    [":hover"]: {color: orange}
})

// Beratung
export const slideIncloser = mq({
    width: ["15vw","30vw", "30vw","30vw"],
    height: "100vh",
    position: "absolute",
    zIndex: 15
})
export const slidein = mq({
    position: "fixed", 
    height: ["80vh", "80vh", "80vh", "80vh"],
    width: ["85vw","70vw", "70vw","70vw"],
    right: ["0%", "0%", "10%", "10%"],
    top: ["0%", "0%", "10%", "10%"],
    background: background2,
    overflowX: "visible",
    overflowY: "visible",
    boxShadow: "-3px 0px 12px 2px " + darkgrey, 
    fontSize: "16px",
    padding: ["1em 3em", "3em 3em", "3em 3em", "3em 3em"],
    zIndex: 14,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center"
})
export const closer = {
    fontSize: "1.2rem",
    textTransform: "uppercase",
    fontWeight: 700,
    color: orange,
    fontFamily: "Montserrat",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    position: "absolute",
    top: "0.6rem",
    right: "0.6rem",
    zIndex: 20,
    cursor: "pointer"
}
export const beratungscard = mq({
    display: "flex",
    flexDirection: "row", 
    alignItems: "center", 
    width: ["100%", "100%", "48%"], 
    height: "auto",
    fontSizte: "14px",
    padding: "1em 1em 1em 0", 
    marginBottom: "2em",
    background: background1, 
    minWidth: "24em", 
    transition: "scale 0.4s ease, box-shadow 0.4s ease"
})
export const beratungsModal = {
    height: "100%", 
    position: "relative", 
    padding: "2em", 
    overflowY: "scroll",
}

// Akademie
export const akademieselector = mq({
    cursor: "pointer",
     width: ["100%", "100%", "32%", "32%"],
     height: "auto",
     padding: "0.6rem 1.2rem" , 
     borderRadius: "6px",
     marginBottom: ["0.6rem", "0.6rem", "2.4rem", "2.4rem"], 
     background: background1,
     display: "flex",
     alignItems:"center",
     flexDirection: "row",
     justifyContent: "flex-start",
})
export const blogselector = mq({
    cursor: "pointer",
    width: "auto",
     height: "auto",
     padding: "0.6rem 2.4rem", 
     borderRadius: "6px",
     marginBottom: ["0.6rem", "0.6rem", "1.8rem", "1.8rem"], 
     background: background1,
     display: "flex",
     alignItems:"center",
     flexDirection: "row",
     justifyContent: "flex-start",
})

//team
export const teamsite = mq({
    padding: "0em", 
    display: "flex", 
    flexDirection: "row", 
    flexWrap: "wrap", 
    alignItems: "flex-start", 
    justifyContent: ["center","space-between", "space-between","space-between"],
    maxWidth: "1070px"
})
export const teamsiteTop= mq({
    padding: "0em", 
    display: "flex", 
    flexDirection: "row", 
    flexWrap: "wrap", 
    alignItems: "flex-start", 
    justifyContent: "space-evenly",
    maxWidth: "1070px"
})


export const teamcard = mq({
    width: "100%", 
    overflow: "hidden",
    padding: "0.2em",
    marginTop: "2em",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems:"center",
    justifyContent: "flex-start",
    position: "relative",
})

// Blog
export const blogheader = mq({
    height: ["30vh", "30vh", "50vh","50vh"],
    marginLeft:[ "5%", "5%","11%", "11%"] ,
    marginTop: ["6.4em", "4.4em", "6em", "6em"],  
    width:["90%", "90%", "84%", "84%"],
    borderRadius: "2em 0 2em 0",
    overflow: "hidden !important",
    boxShadow: "0.5em 0.5em 0.9em -0.4em " + darkgrey,
})

// Events
export const eventdates = mq({
    display: "flex", 
    flexDirection: "row", 
    justifyContent: "space-between", 
    flexWrap: "wrap",
    width: "100%", 
    margin: "3em 0 0 0",
    background: "#F4EAE1",
    padding: "1em", 
    borderRadius: "0.2em",
    width: ["100%", "100%","100%","100%"]
})
export const eventdatesbottom = mq({
    padding: "1.2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginTop: "2.4rem",
    order: [3,3,3,3],
    display: "flex", 
    flexWrap: "wrap",
    width: ["100%","100%", "100%", "100%"], 
    background: background3,
    borderRadius: "0.2em", 

})
export const eventcardsmall = mq({
    cursor: "pointer", 
    padding:" 0.8em 1.6em",
    overflow: "visible",
    display: "flex", 
    flexDirection: "row", 
    alignItems: "center", justifyContent: "space-between",  
    borderRadius: "0.4em",
    background: white,
    marginBottom: "1em",
    width: "auto",
    position: "relative",
})
export const eventcard = mq({
    width: "100%",
    marginBottom: "1.4em", 
    fontSize: "16px",
    padding: "0.8em 1.6em", 
    overflow: "visible",
    borderRadius: "1.2em 0 1.2em 0",
    background: white,
    cursor: "pointer",
    transition: "all 0.2s ease-out",
    position: "relative"
})

export const infocard = mq({
    boxShadow: "4px 4px 10px -5px" + darkgrey, 
    flex: ["100%", "100%", "30%", "30%"],
    padding: "1.2rem", 
    background: background3,
    borderRadius: "6px"

    
})

// footer
export const footerelement = mq({
    maxWidth: "16em",
    display: ["flex","flex","flex", "flex"],
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "100%",
    ["p"]: {
        fontSize: "0.93em",
        lineHeight: "1.18em"
    },
    ["h6"]: {
        fontWeight: 600
    }
})
export const menulogoinline =mq({
    display: ["none", "none", "flex", "flex"],
    transform: "scale(0.8) translateX(-10px)",
    width: "120px",
    position: "relative"
})